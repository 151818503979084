<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between
            ">
            <h4 class="mb-sm-0">Saham</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item active">Daftar Saham</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header align-items-center d-flex">
              <h4 class="card-title mb-0 flex-grow-1">Daftar Saham</h4>
              <router-link v-if="permission.can('share_period create')" :to="{ name: 'share.period.form' }"
                type="button" class="btn btn-primary waves-effect waves-light ms-2">
                <i class="bx bx-plus font-size-16 align-middle me-2"></i>
                Saham
              </router-link>
            </div>

            <div class="card-body">
              <DataTable :url="dataTableUrl" :columns="columnHeaders" :actions="actions" :reload="reloadDataTable" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "../../components/datatable/DataTable.vue";
import { endpoint } from "../../host";
import route from "../../router";
import moment from "moment";
import permissonService from "../../services/permisson-service";
import globalService from '../../services/global-service';

export default {
  components: {
    DataTable,
  },

  created() {
    if (this.permission.can('share_period edit')) {
      this.actions.push({
        icon: "bx bx-edit",
        color: "btn-warning",
        tooltip: "edit",
        onclick: (data) => {
          route.push({
            name: "share.period.form",
            params: data,
          });
        },
      });
    }
  },

  data() {
    return {
      permission: permissonService,
      reloadDataTable: false,
      dataTableUrl: endpoint["share.period"],
      actions: [],
      columnHeaders: [
        { name: "name", text: "Saham" },
        {
          render: (data) => {
            return globalService.toIdr(data.price);
          },
          text: "Harga",
        },
        {
          render: (data) => {
            return new Intl.NumberFormat("id-ID", {}).format(data.slot_quota);
          },
          text: "Jumlah Quota",
        },
        {
          text: "Tanggal Buka",
          render: (data) => {
            return globalService.dateFormat(data.opened_at);
          },
        },
        {
          text: "Tanggal Tutup",
          render: (data) => {
            return globalService.dateFormat(data.closed_at);
          },
        },
        {
          text: "Status",
          render: (data) => {
            if (data.is_active == 0) {
              return '<span class="badge badge-pill bg-danger font-size-11">Non Aktif</span>';
            } else {
              return '<span class="badge badge-pill bg-success font-size-11">Aktif</span>';
            }
          },
        },
      ],
    };
  },
};
</script>

<style>
</style>